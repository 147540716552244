<template>
    <div>
        <v-row no-gutters>
              <v-col
            cols="12"
          >
              <!-- <v-toolbar dense tile dark v-if="current_fight.status!='No Event'" class="pa-0">
                 <div> {{current_fight.event_name}}</div>
              <v-spacer/>
                <v-icon left small color="info">mdi-calendar</v-icon> {{current_fight.event_date | moment("MMM DD, YYYY")}} 
              </v-toolbar> -->
              <v-row class="pa-1" dark no-gutters>
               
              </v-row>
                <div  style="background: #101010;" v-if="current_fight.status!='No Event'">
                  <iframe id="myiframe" seamless scrolling="no" frameBorder="0" src="https://kamura-server.live/xyz/colorgame.html" title="description"></iframe>
                   <!-- <va-player /> -->
                   <!-- <img :src="$store.state.img_dir+'nl.png'" class="ma-auto" height="240" width="100%"/> -->
                </div>
                 <v-row no-gutters class="pt-0" style="background: #fff;">
                    <v-col cols="4"  class="pa-1  text-center font-weight-bold"  style="line-height: 20px;">
                        ROUND #: <br/> 
                        <template v-if="fight.id != 0">
                          {{current_fight.fight_no?current_fight.fight_no:"80208"}}
                        </template>
                        <template v-else>
                            -----
                        </template>
                      </v-col>
                      <v-col cols="4"  class="text-center" >
                        <div>
                           <v-card tile  color="#121212" ><div style="color: yellow !important;">BETTING</div></v-card>
                            <v-card class="text-uppercase" tile dark  :color="FIGHTSTATUS">{{current_fight.status}}</v-card>
                        </div>
                      </v-col>
                    <v-col cols="4" class="text-right pa-1 font-weight-bold mt-1" style="line-height:17px;">
                        <!-- <v-icon left small color="gold">mdi-database</v-icon> -->
                        {{$money(balance)}}<br/><small class="text-warning">JACKPOT PRIZE</small>
                      </v-col>
                 </v-row>
            </v-col>
             <!-- start Betting block -->
              <v-col
            cols="12"
          >
               <v-row  no-gutters>

                <v-col cols="4"  tile >
                    <v-card tile >
                      <v-card tile color="meron" class="text-center font-weight-bold" dark>MERON</v-card>
                      <div class="text-center pa-1 font-weight-bold">{{$money(current_fight.meron_collection)}}</div>
                        <v-divider/>
                      <div class="text-center pa-1 md-font"><v-icon small left color="success">mdi-database-check-outline</v-icon>BET: {{$money(CURRENTMERONBET)}}
                          <template v-if="current_fight.status == 'Closed' && CURRENTMERONBET != 0">
                            = {{$money(calculatewin('Meron'))}}
                      </template>
                
                        </div>
                      <v-divider/>
                      <v-card-actions class="md-font">
                        PAYOUT <small class="text-info"></small>:
                        <v-spacer/>
                          <template v-if="current_fight.meron_win > 0">
                                {{$money((current_fight.meron_win * 100) + 100)}}
                          </template>
                      </v-card-actions>
                    </v-card>
                </v-col>
                  
                  <v-col cols="4"  tile >
                    <v-card tile >
                      <v-card tile color="jackpot" class="text-center  font-weight-bold" dark>JACKPOT</v-card>
                      <div class="text-center pa-1 font-weight-bold">{{$money(current_fight.jackpot)}}</div>
                        <v-divider/>
                      <div class="text-center pa-1 md-font"><v-icon small left color="success">mdi-database-check-outline</v-icon>BET: {{$money(CURRENTMERONBET)}}
                          <template v-if="current_fight.status == 'Closed' && CURRENTMERONBET != 0">
                            = {{$money(calculatewin('Meron'))}}
                      </template>
                
                        </div>
                      <v-divider/>
                      <v-card-actions class="md-font">
                        POT <small class="text-info"></small>:
                        <v-spacer/>
                        {{$money(50000)}}
                      </v-card-actions>
                    </v-card>
                </v-col>
                  <v-col cols="4">
                    <v-card tile>
                       <v-card tile color="wala" class="text-center font-weight-bold" dark>WALA</v-card>
                      <div class="text-center pa-1 font-weight-bold">{{$money(current_fight.wala_collection)}}</div>
                        <v-divider/>
                      <div class="text-center pa-1 md-font"><v-icon small left color="success">mdi-database-check-outline</v-icon>BET: {{$money(CURRENTWALABET)}} 
                      <template v-if="current_fight.status == 'Closed'  && CURRENTWALABET != 0">
                        = {{$money(calculatewin('Wala'))}}
                      </template>
                      </div>
                        <v-divider/>
                      <v-card-actions class="md-font">
                        PAYOUT <small class="text-info"> </small>:
                        <v-spacer/>
                        <template v-if="current_fight.wala_win > 0">
                          {{$money((current_fight.wala_win * 100) + 100)}}
                        </template>
                      </v-card-actions>  
                    </v-card>
                </v-col>
               
                <v-col cols="12"  class="mt-1">
                 <div style="height: 50px;" class="mt-1">
                  <v-row no-gutters>
                      <v-flex class="text-center img-chips"><img :class="mybet.amount==5?'select_amt':''"  @click="mybet.amount = 5" :disabled="current_fight.status == 'Closed'" :src="$store.state.img_dir+'c5.png'"/></v-flex>
                      <v-flex class="text-center img-chips"><img :class="mybet.amount==10?'select_amt':''" @click="mybet.amount = 10" :disabled="current_fight.status == 'Closed'" :src="$store.state.img_dir+'c10.png'"/></v-flex>
                      <v-flex class="text-center img-chips"><img :class="mybet.amount==25?'select_amt':''" @click="mybet.amount = 25" :disabled="current_fight.status == 'Closed'" :src="$store.state.img_dir+'c25.png'"/></v-flex>
                      <v-flex class="text-center img-chips"><img :class="mybet.amount==50?'select_amt':''" @click="mybet.amount = 50" :disabled="current_fight.status == 'Closed'" :src="$store.state.img_dir+'c50.png'"/></v-flex>
                      <v-flex class="text-center img-chips"><img :class="mybet.amount==100?'select_amt':''" @click="mybet.amount = 100" :disabled="current_fight.status == 'Closed'" :src="$store.state.img_dir+'c100.png'"/></v-flex>
                  </v-row>
                </div>   
                </v-col>
                  
                <v-col cols="4"  class="mt-1">
                  
                    <v-btn  tile class="pa-1"  :dark="current_fight.status != 'Closed'"  color="meron"  block :disabled="current_fight.status == 'Closed'" :loading="meronloading" @click="validateBetAmount('Meron')"><v-icon left>mdi-database-arrow-right-outline</v-icon>MERON</v-btn>
                </v-col>
                  <v-col cols="4"  class="mt-1">
                    <v-btn  color="jackpot" class="pa-1"  tile :dark="current_fight.status != 'Closed'"   block :disabled="current_fight.status == 'Closed'" :loading="jackpotloading"  @click="validateBetAmount('Jackpot')"><v-icon left>mdi-database-arrow-right-outline</v-icon> JACKPOT</v-btn>
                </v-col>

                <v-col cols="4"  class="mt-1">
                    <v-btn  color="wala" class="pa-1"  tile :dark="current_fight.status != 'Closed'"   block :disabled="current_fight.status == 'Closed'" :loading="walaloading"  @click="validateBetAmount('Wala')"><v-icon left>mdi-database-arrow-right-outline</v-icon> WALA</v-btn>
                </v-col>
                </v-row>
             </v-col>
            <!-- end Betting block -->
            
        </v-row>
          <!-- <reports-trends :trends="current_fight.trends" :history="history"/> -->
          <va-confirm-bet :show="confirm" :amount="$IsNum(mybet.amount)" :bet_to="bet_to" @DialogEvent="confirmEvent"/>
    
      </div>
    </template>
    <script>
    import md5 from 'md5'
    import { API_MIXINS } from '@/mixins/api_mixins.js'

    import {
      mapMutations
    } from 'vuex'
      export default {
        mixins: [API_MIXINS],
        data: () => ({
          bet_to:"",
          walaloading: false,
          meronloading: false,
          jackpotloading: false,
          CHECKINTERVAL: null,
          confirm:false,
          countdown: false,
          currentbet:[],
          database: null,
          history:[],
          fight:{status:""},
          current_fight: {status:"", fight_no:0},
          mybet:{amount:''},
           admin_comm: .1
        }),
        mounted() {
       
          //  if(this.current_fight.status != "No Event") {
          //        //this.PLAYER()
          //     }
             const starCountRef = this.$fbdbref(this.$fbdb, 'game');
             // console.log(this.$fbdb) 
            this.$fbdbonValue(starCountRef, (snapshot) => {
             this.current_fight = snapshot.val()
              //console.log(snapshot.val())
          });
      
             
        },
        created() {
          //console.log(this.$fbdb)
           


        this.setLoggedIn(true)
            if (this.user == null) {
              this.$router.push('/')
            }else {
              this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
              this.$multibet.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
             this.setData()
            // this.PLAYER()
            }
          this.PING()
         
        },
        beforeDestroy(){
          
        },
        computed:{
           windowClass() {
            if(this.$store.state.responsive){
              return "va-reponsive"
            } else {
              return "va-not-reponsive"
            }
          },
          FIGHTSTATUS(){
            switch(this.current_fight.status) {
                case "Open":
                  return 'success'
                case "Closed":
                  return 'meron'
                 case "Last Call":
                  return 'error'
                 case "Finished":
                  return 'info'
                default: 
                  return 'info'
            }
          },
          FIGHTICON(){
             switch(this.fight.status) {
                case "Open":
                   return 'mdi-check'
                case "Closed":
                  return 'mdi-window-close'
                default: 
                  return 'info'
            }
          },
          balance(){
        return  this.$store.state.balance.balance
      },
          user() {
            if(localStorage.user){
                return JSON.parse(localStorage.user)
            }
            return null
          },
          CURRENTWALABET(){
        var amount = this.currentbet.reduce((res,item)=>{
                  if(item.bet_to == "Wala"){
                       res += this.$IsNum(item.amount)
                  }
                  return res
        }, 0)
        return amount
      },
      CURRENTMERONBET(){
        var amount = this.currentbet.reduce((res,item)=>{
                  if(item.bet_to == "Meron"){
                       res += this.$IsNum(item.amount)
                  }
                  return res
        }, 0)
        return amount
      }
        },
        methods: {
          ...mapMutations(['setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading', 'setFight', 'setToolbar']),
          validateBetAmount(bet_to){
            if(this.mybet.amount >= 5) {
              this.confirm = true
              this.bet_to = bet_to
            } else {
              this.VA_ALERT("error", "Bet limit is 5 points!")
            }
          },
          confirmEvent(e) {
            this.confirm = false
            if(e == 'ok') {
              if(this.bet_to =="Meron") {
                 this.meronloading=true
                 this.sendBet(this.bet_to)
              } else if(this.bet_to =="Wala"){
                  this.walaloading=true
                 this.sendBet(this.bet_to)
              } else if(this.bet_to =="Jackpot"){
                  this.jackpotloading=true
                 this.sendBet(this.bet_to)
              }
              
            } else {
              this.mybet.amount=""
            }
    
          },
          calculatewin(bet_to){
           
            // var amount =  bet_to=='Meron'?(this.CURRENTMERONBET*this.$IsNum3f(this.current_fight.meron_win)) + this.CURRENTMERONBET: (this.CURRENTWALABET*this.$IsNum3f(this.current_fight.wala_win)) + this.CURRENTWALABET
             return this.$IsNum(bet_to)
          },
           bet_amount(bet_to){
             console.log(this.currentbet.bet_to )
            if(this.currentbet.bet_to == bet_to) {
              return this.currentbet.amount 
            } else {
              return 0
            }
          },
         
          getCurrent(){
            this.currentbet = []
              this.$multibet.post("bet/current",{signature: md5( this.current_fight.id+'-'+this.user.id+'-' + this.$moment().format("YYYY-MM-DD"))}).then(response => {
                        response.data.bet != null?this.currentbet = response.data.bet:this.currentbet=[]
                    }).catch(e => {
                     console.log(e.data)
                 })
          },
           sendBet(bet_to){
             if(this.$IsNum(this.mybet.amount) == 0) {
                 this.meronloading=false
                   this.walaloading=false
                   this.jackpotloading = false
                this.VA_ALERT("error", "Enter your beposyt amount!")
               return
             }
             console.log("current_fight:", this.current_fight)
              this.mybet.bet_to = bet_to
              this.mybet.fight_id = this.current_fight.id
              this.mybet.amount = this.$IsNum(this.mybet.amount)
              this.mybet.signature = md5( this.current_fight.id+'-'+this.user.id+'-' + this.$moment().format("YYYY-MM-DD"))
              console.log(this.mybet)
               this.$multibet.post("bet/add",this.mybet).then(response => {
                    if(response.data.status) {
                      if(response.data.res_code== 2000) {
                       this.VA_ALERT("success", response.data.message)
                        this.currentbet =  this.mybet
                        if(bet_to == "Wala") {
                            this.current_fight.wala = this.$IsNum(this.current_fight.wala) + this.mybet.amount
                        } else if (bet_to == "Meron") {
                          this.current_fight.meron  = this.$IsNum(this.current_fight.meron) + this.mybet.amount
                        } else if (bet_to == "Jackpot") {
                          this.current_fight.jackpot  = this.$IsNum(this.current_fight.jackpot) + this.mybet.amount
                        }
                        this.getCurrent()
                        this.CHECK_BALANCE(this.user.id)
                      } else {
                        this.VA_ALERT("warning", response.data.message)
                      }
                    } else {
                      this.VA_ALERT("error", response.data.message)
                    }
                    this.walaloading = false
                       this.meronloading = false
                       this.jackpotloading = false
                   
                    }).catch(e => {
                     console.log(e)
                 })
           }, 
            setData() {
              this.CHECK_BALANCE(this.user.id)
            // // this.GET_ONGOING_EVENTS()
            //    setTimeout(()=>{
            //      this.GET_TRENDS()
            //    }, 2000)
             
          }
        },
      }
    </script>